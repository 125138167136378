import { createSelector } from '@ngrx/store';
import { IAppState } from '../state/app.state';
import { IUserState } from '../state/user.state';

const selectUser = (state: IAppState) => state.user;

export const selectUserdata = createSelector(
    selectUser,
    (state: IUserState) => state.user
);
export const selectUserLoginError = createSelector(
    selectUser,
    (state: IUserState) => state.loginError
);
export const selectUserLoginSuccess = createSelector(
    selectUser,
    (state: IUserState) => state.loginSuccess
);
export const selectUserSession = createSelector(
    selectUser,
    (state: IUserState) => state.session
);

export const selectUserLoading = createSelector(
    selectUser,
    (state: IUserState) => state.loading
);

export const selectUserSelected = createSelector(
    selectUser,
    (state: IUserState) => state.selected
);
