import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/@core/store/state/app.state';
import { selectUserdata } from 'src/app/@core/store/selectors/user.selector';
import { UnsetUserSession } from 'src/app/@core/store/actions/user.action';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  user$: any;
  constructor(
    private store: Store<IAppState>,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.user$ = this.store.select(selectUserdata);
  }
  openPasswordDialog() {
    // test
  }
  openUserDialog() {
    // test
  }
  logout() {
    this.store.dispatch(new UnsetUserSession());
    // console.log('test');
  }
  newCourses() {
    this.router.navigate(['/preview/edit']);
  }
  goTo(link) {
    this.router.navigate([link]);
  }

}
