import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NbThemeModule, NbLayoutModule, NbMenuModule, NbDialogService, NbDialogModule } from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { appReducers } from './@core/store/reducers/app.reducer';
import { EffectsModule } from '@ngrx/effects';
import { UserLessonEffects } from './@core/store/effects/userlesson.effects';
import { UserEffects } from './@core/store/effects/user.effect';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { SharedModule } from './@shared/shared.module';

const config = {
  autoFocus: true,
  closeOnEsc: true,
  closeOnBackdropClick: false,
};
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NbMenuModule.forRoot(),
    NbThemeModule.forRoot({ name: 'default' }),
    NbLayoutModule,
    NbEvaIconsModule,
    HttpClientModule,
    StoreModule.forRoot(appReducers),
    EffectsModule.forRoot([UserLessonEffects, UserEffects]),
    StoreDevtoolsModule.instrument(),
    SweetAlert2Module.forRoot(),
    SharedModule,
    NbDialogModule.forRoot(config),
  ],
  providers: [NbDialogService],
  bootstrap: [AppComponent]
})
export class AppModule { }
