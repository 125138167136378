import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
    Authorization: "faketoken",
  }),
};
@Injectable({
  providedIn: "root",
})
export class RequestService {
  // public apiUrl = "http://api.haizara.mg";
  // public apiUrl = "http://localhost:3002/api";
  public apiUrl = "https://haizara.kingatech.pro/api";
  constructor() {}

  setHeaderToken(token: string) {
    httpOptions.headers = httpOptions.headers.set("Authorization", token);
  }

  getHeaderToken() {
    return httpOptions;
  }
}
