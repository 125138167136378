<div class="container-fluid">
    <div class="row">
        <div class="col-4">
            <div class="contact-card">
                <h4>Nous contacter</h4>
                <ul>
                    <li>
                        <i class="fas fa-envelope"></i> haizara@learn.com
                    </li>
                    <li>
                        <i class="fas fa-phone"></i> 034 00 000 00
                    </li>
                    <li>
                        <i class="fas fa-map-marker-alt"></i> Ambohitsoa Antananarivo
                    </li>
                </ul>
            </div>
        </div>
        <div class="col-8">
            <div class="footer-info-card">
                <h4><i class="fas fa-glasses"></i> Vous pouvez aussi publier votre propre cours</h4>
                <p>Vous pouvez partager vos savoirs en publiant vos propres cours. </p>
                <button class="like-nb-btn-primary" routerLink="/preview/edit">Publier</button>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid footer">
    <div class="row">
        <div class="col-3">
            <h5>Haizara</h5>
            <ul>
                <li>
                    <a>
                        Lorem lorem Lorem lorem
                    </a>
                </li>
                <li>
                    <a>
                        Lorem lorem Lorem lorem
                    </a>
                </li>
                <li>
                    <a>
                        Lorem lorem Lorem lorem
                    </a>
                </li>
            </ul>
        </div>
        <div class="col-3">
            <h5>Liens</h5>
            <ul>
                <li>
                    <a routerLink="/home">
                        Accueil
                    </a>
                </li>
                <li>
                    <a routerLink="/preview/edit">
                        Créer un nouveau cours
                    </a>
                </li>
                <li>
                    <a routerLink="/home">
                        Conditions et termes d'utilisation
                    </a>
                </li>
            </ul>
        </div>
        <div class="col-3">
            <h5>Cours</h5>
            <ul>
                <li>
                    <a>
                        Mathématiques
                    </a>
                </li>
                <li>
                    <a>
                        Programmation
                    </a>
                </li>
                <li>
                    <a>
                        Comptabilité
                    </a>
                </li>
            </ul>
        </div>
        <div class="col-3">
            <h5>Réseaux sociaux</h5>
            <div class="flex space-between icons">
                <a class="fb-icon"><i class="fab fa-facebook-f"></i></a>
                <a class="twitter-icon"><i class="fab fa-twitter"></i></a>
                <a class="instagram-icon"><i class="fab fa-instagram"></i></a>
                <a class="youtube-icon"><i class="fab fa-youtube"></i></a>
            </div>
        </div>
        <div class="col-12">
            <div class="copyright text-center">
                &copy; Copyright Haizara | Tout droit réservé
            </div>
        </div>
    </div>
</div>