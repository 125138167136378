<div class="header-container m-l-auto" *ngIf="user$ | async; let user">
    <nb-actions size="small">
        <!-- <nb-action class="control-item" size="medium" icon="bell-outline" nbPopoverPlacement="bottom"
            [nbPopover]="notificationTemplate" [badgeText]="notificationsLength" badgeStatus="danger"
            badgePosition="top right"></nb-action> -->
        <nb-action class="control-item" (click)="goTo('/courses')"><nb-icon icon="book-outline"></nb-icon> </nb-action>
        <nb-action class="control-item" icon="plus-outline" (click)="newCourses()"></nb-action>
        <nb-action class="user-action pointer" (click)="goTo('/me/profil')">
            <nb-user [onlyPicture]="false" [name]="user.firstname" [picture]="user.user_pic || '/assets/imgs/userpic.png'">
            </nb-user>
        </nb-action>
        <nb-action class="control-item" icon="log-out-outline" [swal]="confirmSwal"></nb-action>
    </nb-actions>
</div>


<div class="header-container m-l-auto" *ngIf="!(user$ | async)">
    <nb-actions size="small">
        <nb-action class="control-item" (click)="goTo('/home')"><nb-icon icon="home-outline"></nb-icon> Accueil</nb-action>
        <nb-action class="control-item" (click)="goTo('/courses')"><nb-icon icon="book-outline"></nb-icon> Cours</nb-action>
        <nb-action class="control-item" (click)="goTo('/auth/signin')"><nb-icon icon="person-outline"></nb-icon> Se connecter</nb-action>
        <nb-action class="control-item" (click)="goTo('/auth/signup')"><nb-icon icon="log-in-outline"></nb-icon> S'inscrire</nb-action>
    </nb-actions>
</div>

<swal #confirmSwal title="Se déconnecter ?" icon="question" [showCancelButton]="true" [reverseButtons]="true"
    confirmButtonText="Oui" cancelButtonText="Non" (confirm)="logout()">
</swal>

<swal #disconnectOrderSwal title="Votre compte a été désactivé" icon="warning"
    confirmButtonText="Ok"  (close)="logout()">
</swal>
