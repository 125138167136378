import { Injectable } from '@angular/core';
import { ofType, Actions, Effect } from '@ngrx/effects';

import {
    GetUserData,
    EUserAction,
    GetUserDataSuccess,
    CheckLoginUser,
    CheckLoginUserSuccess,
    CheckLoginUserError,
    SaveUser,
    CheckUserToken,
    UserTokenValid,
    UserTokenInValid,
    UnsetUserSession,
    UnsetUserSessionSuccess,
    StopUserLoading,
    UpdateUserData,
    UpdateUserDataSuccess,
    GetUserProfil,
    GetUserProfilSuccess
} from '../actions/user.action';
import { switchMap, map, tap} from 'rxjs/operators';
import { of } from 'rxjs';
import { UserService } from '../../services/user/user.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../state/app.state';
import { Router } from '@angular/router';

@Injectable()
export class UserEffects {

    @Effect()
    CheckLoginUser$ = this.actions$.pipe(
        ofType<CheckLoginUser>(EUserAction.CheckLoginUser),
        switchMap(action => {
            return this.userService.checkLogin(action.payload).pipe(
                map(res => {
                    this.store.dispatch(new StopUserLoading());
                    if (res.success) {
                        localStorage.setItem('virsc_user_id', res.data._id);
                        localStorage.setItem('virsc_user_token', res.token);
                        return new CheckLoginUserSuccess(res.data);
                    } else {
                        return new CheckLoginUserError(res.message);
                    }
                })
            );
        })
    );

    @Effect()
    GetUserData$ = this.actions$.pipe(
        ofType<GetUserData>(EUserAction.GetUserData),
        switchMap(action => {
            return this.userService.getUserData().pipe(
                map(res => {
                    console.log(res);
                    return new GetUserDataSuccess(res.data);
                })
            );
        })
    );

    @Effect()
    GetUserProfil$ = this.actions$.pipe(
        ofType<GetUserProfil>(EUserAction.GetUserProfil),
        switchMap(action => {
            return this.userService.getUserData(action.payload).pipe(
                map(res => {
                    console.log(res);
                    return new GetUserProfilSuccess(res.data);
                })
            );
        })
    );

    @Effect()
    SaveUser$ = this.actions$.pipe(
        ofType<SaveUser>(EUserAction.SaveUser),
        switchMap(action => {
            return this.userService.saveUser(action.payload).pipe(
                map(res => {
                    this.store.dispatch(new StopUserLoading());
                    if (res.success) {
                        return new CheckLoginUserSuccess(res.data);
                    } else {
                        return new CheckLoginUserError(res.message);
                    }
                })
            );
        })
    );

    @Effect()
    CheckUserToken$ = this.actions$.pipe(
        ofType<CheckUserToken>(EUserAction.CheckUserToken),
        switchMap(action => {
            return this.userService.getUserData(action.payload).pipe(
                map(res => {
                    console.log('token verification', res);
                    if (res.success) {
                        this.store.dispatch(new GetUserDataSuccess(res.data));
                        return new UserTokenValid();
                    } else {
                        return new UserTokenInValid();
                    }
                })
            );
        })
    );

    @Effect({dispatch: false})
    UnsetUserSession$ = this.actions$.pipe(
        ofType<UnsetUserSession>(EUserAction.UnsetUserSession),
        tap((action) => {
            localStorage.removeItem('virsc_user_id');
            localStorage.removeItem('virsc_user_token');
            console.log('here');
            this.store.dispatch(new UnsetUserSessionSuccess());
            this.router.navigate(['/courses']);
        })
    );

    @Effect({dispatch: false})
    UpdateUserData$ = this.actions$.pipe(
        ofType<UpdateUserData>(EUserAction.UpdateUserData),
        tap(action => {
            this.userService.updateUserData(action.payload).pipe().subscribe(res => {
                this.store.dispatch(new StopUserLoading());
                this.store.dispatch(new UpdateUserDataSuccess(action.payload));
                this.router.navigate(['/me/profil']);
            });
        })
    );

    constructor(
        private actions$: Actions,
        private userService: UserService,
        private store: Store<IAppState>,
        private router: Router
    ) {}
}
