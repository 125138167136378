import { ActionReducerMap } from '@ngrx/store';
import { routerReducer } from '@ngrx/router-store';
import { IAppState } from '../state/app.state';
import { userLessonReducers } from './userlesson.reducer';
import { userReducers } from './user.reducer';

export const appReducers: ActionReducerMap<IAppState, any> = {
    router: routerReducer,
    userLesson: userLessonReducers,
    user: userReducers
};

