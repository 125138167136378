import { Action } from '@ngrx/store';
import { IUser } from '../../../models/user.interface';

export enum EUserAction {
    GetUserData = '[user] Get user data',
    GetUserDataSuccess = '[user] Get user data success',
    SaveUser = '[user] Save user',
    SaveUserSuccess = '[user] Save user success',
    CheckLoginUser = '[user] Check user login',
    CheckLoginUserSuccess = '[user] Check user login success',
    CheckLoginUserError = '[user] Check user login error',
    CheckUserToken = '[user] Check local token',
    UserTokenValid = '[user] User token valid',
    UserTokenInValid = '[user] User token invalid',
    UnsetUserSession = '[user] Unset user token',
    UnsetUserSessionSuccess = '[user] Unset user token success',
    StartUserLoading = '[user] User loading start',
    StopUserLoading = '[user] User loading stop',
    UpdateUserData = '[user] Update user data',
    UpdateUserDataSuccess = '[user] Update user data success',
    GetUserProfil = '[user] Get User profil',
    GetUserProfilSuccess = '[user] Get User profil success'
}

export class GetUserData implements Action {
    public readonly type = EUserAction.GetUserData;
}

export class GetUserDataSuccess implements Action {
    public readonly type = EUserAction.GetUserDataSuccess;
    constructor(public payload: IUser) {}
}

export class SaveUser implements Action {
    public readonly type = EUserAction.SaveUser;
    constructor(public payload: any) {}
}

export class SaveUserSuccess implements Action {
    public readonly type = EUserAction.SaveUserSuccess;
    constructor(public payload: string) {}
}

export class CheckLoginUser implements Action {
    public readonly type = EUserAction.CheckLoginUser;
    constructor(public payload: {email: string, password: string}) {}
}

export class CheckLoginUserSuccess implements Action {
    public readonly type = EUserAction.CheckLoginUserSuccess;
    constructor(public payload: IUser) {}
}

export class CheckLoginUserError implements Action {
    public readonly type = EUserAction.CheckLoginUserError;
    constructor(public payload: string) {}
}

export class CheckUserToken implements Action {
    public readonly type = EUserAction.CheckUserToken;
    constructor(public payload: string) {}
}

export class UserTokenValid implements Action {
    public readonly type = EUserAction.UserTokenValid;
}

export class UserTokenInValid implements Action {
    public readonly type = EUserAction.UserTokenInValid;
}
export class UnsetUserSession implements Action {
    public readonly type = EUserAction.UnsetUserSession;
}
export class UnsetUserSessionSuccess implements Action {
    public readonly type = EUserAction.UnsetUserSessionSuccess;
}
export class StartUserLoading implements Action {
    public readonly type = EUserAction.StartUserLoading;
}
export class StopUserLoading implements Action {
    public readonly type = EUserAction.StopUserLoading;
}

export class UpdateUserData implements Action {
    public readonly type = EUserAction.UpdateUserData;
    constructor(public payload: any) {}
}

export class UpdateUserDataSuccess implements Action {
    public readonly type = EUserAction.UpdateUserDataSuccess;
    constructor(public payload: any) {}
}
export class GetUserProfil implements Action {
    public readonly type = EUserAction.GetUserProfil;
    constructor(public payload: string) {}
}

export class GetUserProfilSuccess implements Action {
    public readonly type = EUserAction.GetUserProfilSuccess;
    constructor(public payload: IUser) {}
}

export type UserActions =
    | GetUserData
    | GetUserDataSuccess
    | CheckLoginUser
    | CheckLoginUserSuccess
    | CheckLoginUserError
    | SaveUser
    | SaveUserSuccess
    | CheckUserToken
    | UserTokenValid
    | UserTokenInValid
    | UnsetUserSession
    | UnsetUserSessionSuccess
    | StartUserLoading
    | StopUserLoading
    | UpdateUserData
    | UpdateUserDataSuccess
    | GetUserProfil
    | GetUserProfilSuccess
    ;
