import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'app-prompt',
  templateUrl: './prompt.component.html',
  styleUrls: ['./prompt.component.scss']
})
export class PromptComponent implements OnInit {
  data: any;
  constructor(
    private dialogRef: NbDialogRef<PromptComponent>
  ) { }

  ngOnInit(): void {
  }

  close(): void {
    this.dialogRef.close();
  }

  confirm(): void {
    if (!this.data.specialForm) {
      if (!this.data.dontCloseOnconfirm) {
        this.data.onconfirm(this.data.forms);
        this.close();
      } else {
        this.data.onconfirm(this.data.forms, this.close);
      }
    } else if (this.data.specialForm === 'publish_course') {
      if (this.data.field.privacy.value === 'private') {
        if (!this.data.field.password.value) {
          document.querySelector('#prompErrorForm').innerHTML = 'Mot de passe requis';
          return;
        }
        if (!this.data.field.confirmPass.value) {
          document.querySelector('#prompErrorForm').innerHTML = 'Vérification du mot passe requis';
          return;
        }
        if (this.data.field.confirmPass.value !== this.data.field.password.value) {
          document.querySelector('#prompErrorForm').innerHTML = 'Les deux mot de passe ne correspondent pas';
          return;
        }
        if (this.data.field.beginAt.value >= this.data.field.endAt.value) {
          document.querySelector('#prompErrorForm').innerHTML = 'L\'horraire du cours est invalide';
          return;
        }
        if (
          !this.data.field.days.Monday.value &&
          !this.data.field.days.Tuesday.value &&
          !this.data.field.days.Wednesday.value &&
          !this.data.field.days.Thursday.value &&
          !this.data.field.days.Friday.value &&
          !this.data.field.days.Saturday.value &&
          !this.data.field.days.Sunday.value
        ) {
          document.querySelector('#prompErrorForm').innerHTML = 'Choisissez au moins un jour de la semaine';
          return;
        }
      }
      this.data.onconfirm({
        privacy: this.data.field.privacy.value,
        password: this.data.field.password.value,
        beginAt: this.data.field.beginAt.value,
        endAt: this.data.field.endAt.value,
        days: {
          Sunday: this.data.field.days.Sunday.value,
          Monday: this.data.field.days.Monday.value,
          Tuesday: this.data.field.days.Tuesday.value,
          Wednesday: this.data.field.days.Wednesday.value,
          Thursday: this.data.field.days.Thursday.value,
          Friday: this.data.field.days.Friday.value,
          Saturday: this.data.field.days.Saturday.value
        }
      });
      this.close();
    }

  }

  cancel(): void {
    if (this.data.oncancel) {
      this.data.oncancel();
    }
    this.close();
  }

}
