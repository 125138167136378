import { IUser } from '../../../models/user.interface';

export interface IUserState {
    user: IUser;
    loginError: string;
    loginSuccess: string;
    session: boolean;
    loading: boolean;
    selected: IUser;
}

export const initialUserState = {
    user: null,
    loginError: null,
    loginSuccess: null,
    session: null,
    loading: false,
    selected: null
};

