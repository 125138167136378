<nb-layout>
    <nb-layout-header fixed>
        <div class="header-container">
            <div class="logo-container">
                <a class="logo" routerLink="/home" class="brand"><img src="/assets/imgs/logo.png" alt=""> <span class="text-blue">Hai</span><span class="text-green">zara</span></a>
            </div>
        </div>
        <div class="header-container ml-auto">
            <app-navbar></app-navbar>
        </div>
    </nb-layout-header>
    <nb-layout-column>
        <router-outlet></router-outlet>
    </nb-layout-column>
</nb-layout>