import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IsSignedInGuard } from './@core/guards/is-signed-in.guard';


const routes: Routes = [
    { path: '', redirectTo: 'courses', pathMatch: 'full'},
    { path: 'editor/:id',
      canActivate: [IsSignedInGuard] ,
      loadChildren: () => import('./pages/editor/editor.module').then(m => m.EditorModule)},
    { path: 'preview', loadChildren: () => import('./pages/preview/preview.module').then(m => m.PreviewModule)},
    { path: 'read/:id/:chapter',
      canActivate: [IsSignedInGuard] ,
      loadChildren: () => import('./pages/read/read.module').then(m => m.ReadModule) },
    { path: 'read/:id',
      canActivate: [IsSignedInGuard] ,
      loadChildren: () => import('./pages/read/read.module').then(m => m.ReadModule) },
    { path: 'courses', loadChildren: () => import('./pages/courses/courses.module').then(m => m.CoursesModule)},
    { path: 'auth', loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule)},
    {
      path: 'me',
      canActivate: [IsSignedInGuard],
      loadChildren: () => import('./pages/me/me.module').then(m => m.MeModule)
    },
    {
      path: 'users',
      canActivate: [IsSignedInGuard],
      loadChildren: () => import('./pages/me/me.module').then(m => m.MeModule)
    },
    {
      path: 'payment/:id',
      loadChildren: () => import('./pages/payment/payment.module').then(m => m.PaymentModule)
    },
    {
      path: 'home',
      loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule)
    }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
