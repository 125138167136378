import { Injectable } from '@angular/core';
import { Actions, ofType, Effect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { switchMap, tap, map} from 'rxjs/operators';
import { of } from 'rxjs';
import { Router } from '@angular/router';

import {
    EUserLessonAction,
    CreateLesson,
    CreateLessonSuccess,
    GetLessonInEdition,
    InsertLessonToLocal,
    GetLessonInEditionSuccess,
    CreateChapter,
    CreateChapterSuccess,
    UpdateChapterName,
    UpdateChapterNameSuccess,
    UpdateChapterContent,
    UpdateChapterContentSuccess,
    PublishCourse,
    PublishCourseSuccess,
    GetLesson,
    GetLessonSuccess,
    GetLessons,
    GetLessonsSuccess,
    CoursePassNeeded,
    CourseNotAvailableNow,
    RateCourse,
    CommentCourse,
    GetUserLessons,
    UpdateLesson,
    GetLessonMinSuccess,
    GetLessonMin,
    LessonNeedPayment,
    LessonPayment,
    LessonPaymentSuccess,
    SearchLesson,
    SearchLessonSuccess,
    StopLessonLoading
} from '../actions/userLesson.action';
import { LessonService, EditorService } from '../../services';
import { IAppState } from '../state/app.state';
import { NbDialogService } from '@nebular/theme';
import { PromptComponent } from 'src/app/@shared/prompt/prompt.component';
import { StopUserLoading } from '../actions/user.action';

@Injectable()
export class UserLessonEffects {
    errorLesson: string;
    @Effect()
    GetLesson$ = this.actions$.pipe(
        ofType<GetLesson>(EUserLessonAction.GetLesson),
        switchMap(action => {
            return this.lessonService.getLesson(action.payload).pipe(
                map(res => {
                    if (res.success) {
                        return new GetLessonSuccess(res.data);
                    } else if (!res.success && res.message === 'NEED_PASS') {
                        return new CoursePassNeeded(action.payload);
                    } else if (!res.success && res.message === 'WRONG_PASS') {
                        this.errorLesson = 'Mot de passe incorrect';
                        console.log('wroong');
                        return new CoursePassNeeded(action.payload);
                    } else if (!res.success && res.message === 'NOT_AVAILABLE_NOW') {
                        return new CourseNotAvailableNow(res.data);
                    } else if (!res.success && res.message === 'PAYANT') {
                        return new LessonNeedPayment(action.payload);
                    }
                })
            );
        })
    );

    @Effect()
    GetLessonMin$ = this.actions$.pipe(
        ofType<GetLessonMin>(EUserLessonAction.GetLessonMin),
        switchMap(action => {
            console.log('getting lesson minimal for preview');
            return this.lessonService.getLessonPreview(action.payload).pipe(
                map(res => {
                    console.log(res);
                    if (res.success) {
                        return new GetLessonMinSuccess(res.data);
                    }
                })
            );
        })
    );

    @Effect()
    GetLessons$ = this.actions$.pipe(
        ofType<GetLessons>(EUserLessonAction.GetLessons),
        switchMap(action => {
            return this.lessonService.getLessons().pipe(
                map(res => {
                    if (res.success) {
                        return new GetLessonsSuccess(res.data);
                    }
                })
            );
        })
    );

    @Effect()
    CreateLesson$ = this.actions$.pipe(
        ofType<CreateLesson>(EUserLessonAction.CreateLesson),
        switchMap((action) => {
            return this.lessonService.createLesson(action.payload).pipe(
                map(res => {
                    this.store.dispatch(new StopUserLoading());
                    this.store.dispatch(new InsertLessonToLocal({...action.payload, id: res.data}));
                    return new CreateLessonSuccess(res.data);
                })
            );
        })
    );

    @Effect({dispatch: false})
    CreateLessonSuccess$ = this.actions$.pipe(
        ofType<CreateLessonSuccess>(EUserLessonAction.CreateLessonSuccess),
        tap(action => {
            this.router.navigate(['/editor', action.payload]);
        })
    );

    @Effect()
    GetLessonInEdition$ = this.actions$.pipe(
        ofType<GetLessonInEdition>(EUserLessonAction.GetLessonInEdition),
        switchMap((action) => {
            return this.lessonService.getLesson(action.payload).pipe(
                map(res => {
                    if (res.success) {
                        return new GetLessonInEditionSuccess(res.data);
                    }
                })
            );
        })
    );

    @Effect()
    CreateChapter$ = this.actions$.pipe(
        ofType<CreateChapter>(EUserLessonAction.CreateChapter),
        switchMap((action) => {
            return this.lessonService.createChapter(action.payload.chapterName, action.payload.lessonId).pipe(
                map(res => {
                    const chapter = {title: action.payload.chapterName, _id: res.data, content: ''};
                    this.editorService.addChapter(chapter);
                    this.store.dispatch(new StopUserLoading());
                    return new CreateChapterSuccess();
                })
            );
        })
    );

    @Effect()
    UpdateChapterName$ = this.actions$.pipe(
        ofType<UpdateChapterName>(EUserLessonAction.UpdateChapterName),
        switchMap((action) => {
            return this.lessonService.updateChapter(action.payload.chapterId, {title: action.payload.name}).pipe(
                map(res => {
                    action.payload.callback();
                    return new UpdateChapterNameSuccess(action.payload.course);
                })
            );
        })
    );

    @Effect()
    UpdateChapterContent$ = this.actions$.pipe(
        ofType<UpdateChapterContent>(EUserLessonAction.UpdateChapterContent),
        switchMap((action) => {
            return this.lessonService.updateChapter(action.payload.chapterId, {content: action.payload.content}).pipe(
                map(res => {
                    action.payload.callback();
                    this.store.dispatch(new StopUserLoading());
                    return new UpdateChapterContentSuccess (action.payload.course);
                })
            );
        })
    );

    @Effect()
    PublishCourse$ = this.actions$.pipe(
        ofType<PublishCourse>(EUserLessonAction.PublishCourse),
        switchMap((action) => {
            const data: any = {privacy: action.payload.privacy};
            if (action.payload.password) {
                data.password = action.payload.password;
                data.days = action.payload.days;
                data.beginAt = action.payload.beginAt;
                data.endAt = action.payload.endAt;
            }
            return this.lessonService.setCoursePrivacy(action.payload.courseId, data).pipe(
                map(res => {
                    console.log(res);
                    if (res.success) {
                        this.store.dispatch(new StopUserLoading());
                        // console.log
                        return new PublishCourseSuccess(action.payload.courseId);
                    }
                })
            );
        })
    );

    @Effect({dispatch: false})
    PublishCourseSuccess$ = this.actions$.pipe(
        ofType<PublishCourseSuccess>(EUserLessonAction.PublishCourseSuccess),
        tap(action => {
            this.router.navigate(['/read/', action.payload]);
        })
    );

    @Effect({dispatch: false})
    CoursePassNeeded$ = this.actions$.pipe(
        ofType<CoursePassNeeded>(EUserLessonAction.CoursePassNeeded),
        tap(action => {
            this.dialog.open(PromptComponent, {context: {
                data: {
                    error: this.errorLesson,
                    onconfirm: (formValue, close) => {
                        console.log(formValue);
                        const id = (action.payload as any).id ? (action.payload as any).id : action.payload as string;
                        this.store.dispatch(new GetLesson({
                            id, password: formValue[0].value
                        }));
                    },
                    oncancel: () => {
                        this.router.navigate(['/courses']);
                    },
                    title: `Ce cours est privé`,
                    subtitle: 'Veuillez entrer le mot de passe du cours pour continuer !',
                    cancelBtnText: 'Quitter',
                    confirmBtnText: 'Valider',
                    forms: [
                        {
                            element: 'input',
                            value: '',
                            label: 'Mot de passe'
                        }
                    ],
                }
            }});
        })
    );
    @Effect({dispatch: false})
    CourseNotAvailableNow$ = this.actions$.pipe(
        ofType<CourseNotAvailableNow>(EUserLessonAction.CourseNotAvailableNow),
        tap(action => {
            let horraireHtml = '';
            const dayToFrench = {
                Monday: 'Lundi',
                Tuesday: 'Mardi',
                Wednesday: 'Mercredi',
                Thursday: 'Jeudi',
                Friday: 'Vendredi',
                Saturday: 'Samedi',
                Sunday: 'Dimanche'
            };
            let daysNb = 0;
            for (const key in action.payload.days) {
                if (action.payload.days[key]) {
                    daysNb++;
                    horraireHtml += `<div class="h-item">${dayToFrench[key]} de ${action.payload.beginAt} à ${action.payload.endAt}</div>`;
                }
            }
            if (daysNb === 7) {
                horraireHtml = `<div class="h-item">Tous les jours de ${action.payload.beginAt} à ${action.payload.endAt}</div>`;
            }
            this.dialog.open(PromptComponent, {context: {
                data: {
                    error: this.errorLesson,
                    onconfirm: () => {
                        this.router.navigate(['/courses']);
                    },
                    oncancel: () => {
                        this.router.navigate(['/courses']);
                    },
                    title: `Ce cours n'est pas disponible à cette heure ci`,
                    subtitle: `
                        Horraire : <br>
                        ${horraireHtml}
                    `,
                    hideCancelBtn: true,
                    confirmBtnText: 'Ok',
                    forms: [
                    ],
                }
            }});
        })
    );

    @Effect({dispatch: false})
    RateLesson$ = this.actions$.pipe(
        ofType<RateCourse>(EUserLessonAction.RateCourse),
        tap(action => {
            this.lessonService.rateCourse(action.payload.courseId, action.payload.ratingData).pipe().subscribe(res => console.log(res));
        })
    );

    @Effect({dispatch: false})
    CommentCourse$ = this.actions$.pipe(
        ofType<CommentCourse>(EUserLessonAction.CommentCourse),
        tap(action => {
            this.lessonService.commentCourse(action.payload.commentId, {comment: action.payload.comment})
            .pipe().subscribe(res => {
                console.log(res);
                this.store.dispatch(new StopUserLoading());
            });
        })
    );

    @Effect()
    GetUserLessons$ = this.actions$.pipe(
        ofType<GetUserLessons>(EUserLessonAction.GetUserLessons),
        switchMap(() => {
            return this.lessonService.getUserLessons().pipe(
                map(res => {
                    if (res.success) {
                        return new GetLessonsSuccess(res.data);
                    }
                })
            );
        })
    );

    @Effect()
    UpdateLesson$ = this.actions$.pipe(
        ofType<UpdateLesson>(EUserLessonAction.UpdateLesson),
        switchMap(action => {
            const id = action.payload.id;
            const lessonData = action.payload.data;
            return this.lessonService.updateLesson(id, lessonData).pipe(
                map(res => {
                    console.log('UpdatingDone');
                    return new StopUserLoading();
                })
            );
        })
    );

    @Effect({dispatch: false})
    LessonNeedPayment$ = this.actions$.pipe(
        ofType<LessonNeedPayment>(EUserLessonAction.LessonNeedPayment),
        tap(action => {
            if (action.payload.id) {
                this.router.navigate(['/payment', action.payload.id]);
            } else {
                this.router.navigate(['/payment', action.payload]);
            }
        })
    );

    @Effect()
    LessonPayment$ = this.actions$.pipe(
        ofType<LessonPayment>(EUserLessonAction.LessonPayment),
        switchMap(action => {
            return this.lessonService.payment(action.payload.id, action.payload.code).pipe(
                map(res => {
                    this.store.dispatch(new StopUserLoading());
                    return new LessonPaymentSuccess(action.payload.id);
                })
            );
        })
    );

    @Effect({dispatch: false})
    LessonPaymentSuccess$ = this.actions$.pipe(
        ofType<LessonPaymentSuccess>(EUserLessonAction.LessonPaymentSuccess),
        tap(action => {
            this.router.navigate(['/read', action.payload]);
        })
    );

    @Effect()
    SearchLesson$ = this.actions$.pipe(
        ofType<SearchLesson>(EUserLessonAction.SearchLesson),
        switchMap(action => {
            return this.lessonService.search(action.payload).pipe(
                map(res => {
                    this.store.dispatch(new StopLessonLoading());
                    return new SearchLessonSuccess({query: action.payload, result: res.data});
                })
            );
        })
    );

    constructor(
        private actions$: Actions,
        private lessonService: LessonService,
        private router: Router,
        private store: Store<IAppState>,
        private dialog: NbDialogService,
        private editorService: EditorService
        ) { }
}
