import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { PromptComponent } from './prompt/prompt.component';
import { NbInputModule,
  NbButtonModule,
  NbCheckboxModule,
  NbActionsModule,
  NbIconModule,
  NbPopoverModule,
  NbUserModule} from '@nebular/theme';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';



@NgModule({
  declarations: [FooterComponent, NavbarComponent, PromptComponent],
  imports: [
    CommonModule,
    NbButtonModule,
    NbInputModule,
    ReactiveFormsModule,
    FormsModule,
    NbCheckboxModule,
    SweetAlert2Module,
    NbActionsModule,
    NbIconModule,
    NbUserModule,
    NbPopoverModule
  ],
  entryComponents: [PromptComponent],
  exports: [FooterComponent, PromptComponent, NavbarComponent]
})
export class SharedModule { }
