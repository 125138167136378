import { Action } from '@ngrx/store';
import { ILesson } from '../../../models/lesson.interface';
import { IChapter } from 'src/app/models/chapter.interface';

export enum EUserLessonAction {
    GetLesson = '[User Lesson] Get Lesson',
    GetLessonMin = '[User Lesson] Get Lesson Preview',
    GetLessons = '[User Lesson] Get Lessons',
    GetLessonSuccess = '[User Lesson] Get Lesson success',
    GetLessonMinSuccess = '[User Lesson] Get Lesson Preview success',
    GetLessonsSuccess = '[User Lesson] Get Lessons success',
    CreateLesson = '[User Lesson] Create Lesson',
    CreateLessonSuccess = '[User Lesson] Create Lesson success',
    InsertLessonToLocal = '[User Lesson] Insert lesson to local',
    GetLessonInEdition = '[User Lesson] Get Lesson in edition',
    GetLessonInEditionSuccess = '[User Lesson] Get Lesson in edition success',
    CreateChapter = '[User Lesson] Create Chapter',
    CreateChapterSuccess = '[User Lesson] Create Chapter success',
    NewChapterChecked = '[User Lesson] New Chapter Checked',
    UpdateChapterName = '[User Lesson] Updating chapter name',
    UpdateChapterNameSuccess = '[User Lesson] Update chapter name success',
    UpdateChapterContent = '[User Lesson] Updating chapter content',
    UpdateChapterContentSuccess = '[User Lesson] Update chapter content success',
    PublishCourse = '[User Lesson] Publishing course',
    PublishCourseSuccess = '[User Lesson] Publish course success',
    CoursePassNeeded = '[User Lesson] Need course password',
    CourseNotAvailableNow = '[User Lesson] Course not available now',
    RateCourse = '[User Lesson] Rate Course',
    CommentCourse = '[User Lesson] Comment Course',
    GetUserLessons = '[User lesson] Get User Lessons',
    SearchLesson = '[User lesson] Search',
    SearchLessonSuccess = '[User lesson] Search sucess',
    UpdateLesson = '[User lesson] Update',
    LessonNeedPayment = '[User lesson] Need Payment',
    LessonPayment = '[User lesson] Payment',
    LessonPaymentSuccess = '[User lesson] Payment Success',
    StartLessonLoading = '[User lesson] Start loading',
    StopLessonLoading = '[User lesson] Stop loading'
}

export class GetLesson implements Action {
    public readonly type = EUserLessonAction.GetLesson;
    constructor(public payload: string | {password: string, id: string}) {}
}
export class GetLessonMin implements Action {
    public readonly type = EUserLessonAction.GetLessonMin;
    constructor(public payload: string) {}
}

export class GetLessonSuccess implements Action {
    public readonly type = EUserLessonAction.GetLessonSuccess;
    constructor(public payload: ILesson) {}
}

export class GetLessonMinSuccess implements Action {
    public readonly type = EUserLessonAction.GetLessonMinSuccess;
    constructor(public payload: ILesson) {}
}
export class GetLessons implements Action {
    public readonly type = EUserLessonAction.GetLessons;
}

export class GetUserLessons implements Action {
    public readonly type = EUserLessonAction.GetUserLessons;
}

export class GetLessonsSuccess implements Action {
    public readonly type = EUserLessonAction.GetLessonsSuccess;
    constructor(public payload: ILesson[]) {}
}

export class CreateLesson implements Action {
    public readonly type = EUserLessonAction.CreateLesson;
    constructor(public payload: ILesson) {}
}

export class InsertLessonToLocal implements Action {
    public readonly type = EUserLessonAction.InsertLessonToLocal;
    constructor(public payload: ILesson) {}
}

export class CreateChapterSuccess implements  Action {
    public readonly type = EUserLessonAction.CreateChapterSuccess;
}

export class CreateChapter implements Action {
    public readonly type = EUserLessonAction.CreateChapter;
    constructor(public payload: {chapterName: string, lessonId: string}) {}
}

export class NewChapterChecked implements Action {
    public readonly type = EUserLessonAction.NewChapterChecked;
}

export class UpdateChapterName implements Action {
    public readonly type = EUserLessonAction.UpdateChapterName;
    constructor(public payload: {name: string, chapterId: string, course: ILesson, callback: any}) {}
}
export class UpdateChapterNameSuccess implements Action {
    public readonly type = EUserLessonAction.UpdateChapterNameSuccess;
    constructor(public payload: ILesson) {}
}

export class UpdateChapterContent implements Action {
    public readonly type = EUserLessonAction.UpdateChapterContent;
    constructor(public payload: {content: string, chapterId: string, course: ILesson, callback: any}) {}
}
export class UpdateChapterContentSuccess implements Action {
    public readonly type = EUserLessonAction.UpdateChapterContentSuccess;
    constructor(public payload: ILesson) {}
}

export class CreateLessonSuccess implements Action {
    public readonly type = EUserLessonAction.CreateLessonSuccess;
    constructor(public payload: string) {}
}

export class GetLessonInEdition implements Action {
    public readonly type = EUserLessonAction.GetLessonInEdition;
    constructor(public payload: string) {}
}

export class GetLessonInEditionSuccess implements Action {
    public readonly type = EUserLessonAction.GetLessonInEditionSuccess;
    constructor(public payload: ILesson) {
        console.log('init', payload);
    }
}

export class PublishCourse implements Action {
    public readonly type = EUserLessonAction.PublishCourse;
    constructor(public payload: {courseId: string, privacy: string, password?: string, days?: any, beginAt?: string, endAt?: string}) {}
}

export class PublishCourseSuccess implements Action {
    public readonly type = EUserLessonAction.PublishCourseSuccess;
    constructor(public payload: string) {}
}

export class CoursePassNeeded implements Action {
    public readonly type = EUserLessonAction.CoursePassNeeded;
    constructor(public payload: string | {id: string, password: string}) {}
}

export class CourseNotAvailableNow implements Action {
    public readonly type = EUserLessonAction.CourseNotAvailableNow;
    constructor(public payload: any) {}
}

export class RateCourse implements Action {
    public readonly type = EUserLessonAction.RateCourse;
    constructor(public payload: any) {}
}

export class CommentCourse implements Action {
    public readonly type = EUserLessonAction.CommentCourse;
    constructor(public payload: any) {}
}

export class SearchLesson implements Action {
    public readonly type = EUserLessonAction.SearchLesson;
    constructor(public payload: any) {}
}


export class SearchLessonSuccess implements Action {
    public readonly type = EUserLessonAction.SearchLessonSuccess;
    constructor(public payload: {query: any, result: ILesson[]}) {}
}

export class UpdateLesson implements Action {
    public readonly type = EUserLessonAction.UpdateLesson;
    constructor(public payload: {id: string, data: any}) {}
}

export class LessonNeedPayment implements Action {
    public readonly type = EUserLessonAction.LessonNeedPayment;
    constructor(public payload: any) {}
}

export class LessonPayment implements Action {
    public readonly type = EUserLessonAction.LessonPayment;
    constructor(public payload: {id: string, code: string}) {}
}

export class LessonPaymentSuccess implements Action {
    public readonly type = EUserLessonAction.LessonPaymentSuccess;
    constructor(public payload: string) {}
}

export class StartLessonLoading implements Action {
    public readonly type = EUserLessonAction.StartLessonLoading;
}
export class StopLessonLoading implements Action {
    public readonly type = EUserLessonAction.StopLessonLoading;
}


export type UserLessonActions =
    | GetLesson
    | GetLessonMin
    | GetLessonSuccess
    | GetLessonMinSuccess
    | GetLessons
    | GetLessonsSuccess
    | GetLessonInEdition
    | GetLessonInEditionSuccess
    | CreateLesson
    | CreateLessonSuccess
    | InsertLessonToLocal
    | CreateChapter
    | CreateChapterSuccess
    | NewChapterChecked
    | UpdateChapterName
    | UpdateChapterNameSuccess
    | UpdateChapterContent
    | UpdateChapterContentSuccess
    | PublishCourse
    | PublishCourseSuccess
    | CoursePassNeeded
    | CourseNotAvailableNow
    | RateCourse
    | CommentCourse
    | GetUserLessons
    | SearchLesson
    | SearchLessonSuccess
    | UpdateLesson
    | LessonNeedPayment
    | LessonPayment
    | LessonPaymentSuccess
    | StartLessonLoading
    | StopLessonLoading
    ;

