import { ILesson } from '../../../models/lesson.interface';

export interface IUserLessonState {
    lessons: ILesson[];
    inEdition: ILesson;
    selectedLesson: ILesson;
    newChapter: boolean;
    search: {query: any, result: ILesson[] };
    loading: boolean;
}

export const initialUserLessonState = {
    lessons: [],
    inEdition: null,
    selectedLesson: null,
    newChapter: null,
    search: null,
    loading: null
};
