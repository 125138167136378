import { EUserAction, UserActions } from '../actions/user.action';
import { IUserState, initialUserState } from '../state/user.state';

function translateError(code: string): string {
    switch (code) {
        case 'EMAIL_TAKEN':
            return 'Email déjà utilisé';
        case 'LOGIN_ERR':
            return 'Identifiant invalide';
        default:
            return code;
    }
}

export function userReducers(
    state = initialUserState,
    action: UserActions
): IUserState {
    switch (action.type) {
        case EUserAction.GetUserDataSuccess:
            return {
                ...state,
                user: action.payload
            };
        case EUserAction.GetUserProfilSuccess:
            return {
                ...state,
                selected: action.payload
            };
        case EUserAction.CheckLoginUserSuccess:
            return {
                ...state,
                user: action.payload,
                loginError: null,
                loginSuccess: 'Success',
                session: true,
            };
        case EUserAction.CheckLoginUserError:
            return {
                ...state,
                loginError: translateError(action.payload)
            };
        case EUserAction.UserTokenValid:
            return {
                ...state,
                session: true,
                loginSuccess: 'Success'
            };
        case EUserAction.UserTokenInValid:
            return {
                ...state,
                session: false,
                loginSuccess: null
            };
        case EUserAction.UnsetUserSessionSuccess:
            return {
                ...state,
                user: null,
                session: false,
                loginSuccess: null
            };
        case EUserAction.StartUserLoading:
            return {
                ...state,
                loading: true
            };
        case EUserAction.StopUserLoading:
            return {
                ...state,
                loading: false
            };
        case EUserAction.UpdateUserDataSuccess:
            const user = {...state.user, ...action.payload };
            return {
                ...state,
                user
            };
        default:
            return state;
    }
}
