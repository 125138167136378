import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ILesson } from 'src/app/models/lesson.interface';

export interface Chapter {
  title: string;
  index: number;
}
@Injectable({
  providedIn: 'root'
})

export class EditorService {

  course: BehaviorSubject<any>;
  chapterInEdition: BehaviorSubject<Chapter>;

  constructor() {

    this.course = new BehaviorSubject(null);
    this.chapterInEdition = new BehaviorSubject(null);

    this.course.asObservable();
    this.chapterInEdition.asObservable();
  }

  setCourse(course): any {
    this.course.next(course);
  }

  addChapter(chapter: any): void {
    const temp = {...this.course.value};
    temp.chapters = [...temp.chapters, chapter];
    this.chapterInEdition.next({title: chapter.title, index: temp.chapters.length - 1});
    this.course.next(temp);
  }

  setChapterInEdition(index: number): void {
    console.log(index);
    const chapter: Chapter = {
      title: this.course.value.chapters[index].title,
      index
    };
    this.chapterInEdition.next(chapter);
  }

  setChapterTitleValue(index: number, value: string): void {
    this.course.value.chapters[index].title = value;
    this.course.next(this.course.value);
  }

  setChapterInEditionContent(content: string): void {
    this.course.value.chapters[this.chapterInEdition.value.index].content = content;
    this.course.next(this.course.value);
    this.saveToLocal();
  }

  saveToLocal() {
    localStorage.setItem('vi-course-test', JSON.stringify(this.course.value));
  }
}
