<div class="i-modal" *ngIf="data">
  <div class="i-modal-header">
    {{data.title}}
  </div>
  <div class="i-modal-body">
    <div class="subtitle" *ngIf="data.subtitle" [innerHTML]="data.subtitle"></div>
    <div class="container-fluid" *ngIf="!data.specialForm">
      <div class="relative form-field" *ngFor="let field of data.forms">
        <nb-checkbox [(ngModel)]="field.value" *ngIf="field.element === 'checkbox'">{{field.label}}</nb-checkbox>
        <label *ngIf="field.element !== 'checkbox'">{{field.label}}</label>
        <input *ngIf="field.element === 'input'" [type]="field.type || 'text'" nbInput fullWidth fieldSize="large"
          placeholder="........." [(ngModel)]="field.value">
        <select NbInput *ngIf="field.element === 'select'" [(ngModel)]="field.value">
          <option *ngFor="let op of field.options" [value]="op.value">{{ op.label }}</option>
        </select>
        <label class="form-error sublabel" id="prompErrorForm">{{data.error}}</label>
      </div>
    </div>
    <div class="container-fluid" *ngIf="data.specialForm && data.specialForm === 'publish_course'">
      <div class="row">
        <div class="col-6">
          <div class="relative form-field">
            <label>Le cours sera</label>
            <select NbInput [(ngModel)]="data.field.privacy.value">
              <option *ngFor="let op of data.field.privacy.options" [value]="op.value">{{ op.label }}</option>
            </select>
          </div>
          <div class="form-field" *ngIf="data.field.privacy.value === 'private'">
            <label>Mot de passe pour le cours</label>
            <div class="input-container">
              <i class="fas fa-lock"></i>
              <input type="password" placeholder="........" [(ngModel)]="data.field.password.value">
            </div>
          </div>
          <div class="form-field" *ngIf="data.field.privacy.value === 'private'">
            <label>Verification du mot de passe</label>
            <div class="input-container">
              <i class="fas fa-lock"></i>
              <input type="password" placeholder="........"  [(ngModel)]="data.field.confirmPass.value">
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="form-field" *ngIf="data.field.privacy.value === 'private'">
            <label>Le cours se disponible dès</label>
            <div class="input-container times">
              <i class="fas fa-clock"></i>
              <input type="time" placeholder="........" [(ngModel)]="data.field.beginAt.value">
            </div>
          </div>
          <div class="form-field" *ngIf="data.field.privacy.value === 'private'">
            <label>jusqu'à</label>
            <div class="input-container times">
              <i class="fas fa-clock"></i>
              <input type="time" [min]="data.field.beginAt.value" placeholder="........" [(ngModel)]="data.field.endAt.value">
            </div>
          </div>
          <div class="form-field" *ngIf="data.field.privacy.value === 'private'">
            <label>Tous les</label>
            <div class="weekDays-selector">
              <input type="checkbox" id="weekday-mon" [(ngModel)]="data.field.days.Monday.value" class="weekday" />
              <label for="weekday-mon">L</label>
              <input type="checkbox" id="weekday-tue" [(ngModel)]="data.field.days.Tuesday.value" class="weekday" />
              <label for="weekday-tue">M</label>
              <input type="checkbox" id="weekday-wed" [(ngModel)]="data.field.days.Wednesday.value" class="weekday" />
              <label for="weekday-wed">M</label>
              <input type="checkbox" id="weekday-thu" [(ngModel)]="data.field.days.Thursday.value" class="weekday" />
              <label for="weekday-thu">J</label>
              <input type="checkbox" id="weekday-fri" [(ngModel)]="data.field.days.Friday.value" class="weekday" />
              <label for="weekday-fri">V</label>
              <input type="checkbox" id="weekday-sat" [(ngModel)]="data.field.days.Saturday.value" class="weekday" />
              <label for="weekday-sat">S</label>
              <input type="checkbox" id="weekday-sun" [(ngModel)]="data.field.days.Sunday.value" class="weekday" />
              <label for="weekday-sun">D</label>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <label class="form-error sublabel" id="prompErrorForm">{{data.error}}</label>
      </div>
    </div>
  </div>
  <div class="i-modal-footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-6 text-center" *ngIf="!data.hideCancelBtn">
          <button nbButton status="danger" (click)="cancel()">{{data.cancelBtnText || 'Non'}}</button>
        </div>
        <div class="col-6 text-center m-auto">
          <button nbButton status="primary" (click)="confirm()">{{data.confirmBtnText || 'Oui'}}</button>
        </div>
      </div>
    </div>
  </div>
</div>