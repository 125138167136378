import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { ILesson } from 'src/app/models/lesson.interface';
import { UserService } from '../user/user.service';
import { IChapter } from 'src/app/models/chapter.interface';
import { RequestService } from '../request/request.service';

@Injectable({
  providedIn: 'root'
})
export class LessonService {
  lesson: BehaviorSubject<any>;
  apiUrl;

  constructor(private http: HttpClient, private userService: UserService, private rqs: RequestService) {
    let localLesson = localStorage.getItem('vi-lesson');
    localLesson = localLesson ? JSON.parse(localLesson) : {};
    this.lesson = new BehaviorSubject(localLesson);
    this.lesson.asObservable();
    this.apiUrl = this.rqs.apiUrl;

    // this.http.post(`http://localhost:3005/lesson/save`, {
    //   data: {
    //     "title": "test",
    //     "description":"test",
    //     "video":"est",
    //     "thumbnail":"test",
    //     "vocabularies":[{"eng":null,"mlg":null,"description":null,"img":null}],"level":"beginner"}
    // }).toPromise().then(res => console.log('ressss', res));
  }

  createLesson(lesson: ILesson): Observable<any> {
    lesson = {...lesson, user: this.userService.getUserId()};
    this.lesson.next(lesson);
    console.log(lesson);
    return this.http.post(`${this.apiUrl}/lesson/create`, {data: lesson});
  }

  createChapter(chapterName: string, lessonId: string): Observable<any> {
    return this.http.post(`${this.apiUrl}/chapter/create/${lessonId}`, {title: chapterName});
  }

  getLesson(params: ({ id: string, password: string} | string)): Observable<any> {
    console.log(params);
    if (!(params as any).id) {
      console.log('string params : ', params);
      return this.http.get(`${this.apiUrl}/lesson/read/${params}/${this.userService.getUserId()}`);
    } else {
      params = params as { id: string, password: string};
      return this.http.get(`${this.apiUrl}/lesson/readPrivate/${params.id}/${this.userService.getUserId()}/${params.password}`);
    }
  }

  getLessonPreview(params: string): Observable<any> {
      return this.http.get(`${this.apiUrl}/lesson/preview/${params}`);
  }

  getLessons(): Observable<any> {
    return this.http.get(`${this.apiUrl}/lesson/list`);
  }
  updateChapter(chapterId, content): Observable<any> {
    return this.http.post(`${this.apiUrl}/chapter/update/${chapterId}`, {content});
  }

  setCoursePrivacy(courseId, data): Observable<any> {
    return this.http.post(`${this.apiUrl}/lesson/setprivacy/${courseId}`, data);
  }
  rateCourse(courseId, ratingData): Observable<any> {
    return this.http.post(`${this.apiUrl}/lesson/rateCourse/${courseId}`, ratingData);
  }
  commentCourse(commentId, commentData): Observable<any> {
    console.log(commentId, commentData);
    return this.http.post(`${this.apiUrl}/comment/commentCourse/${commentId}`, commentData);
  }
  getUserLessons(): Observable<any> {
    return this.http.get(`${this.apiUrl}/lesson/getUserLessons/${this.userService.getUserId()}`);
  }
  updateLesson(id, lessonData): Observable<any> {
    return this.http.post(`${this.apiUrl}/lesson/update/${id}`, lessonData);
  }

  payment(id, code): Observable<any> {
    return this.http.post(`${this.apiUrl}/payment/create`, {
      user: this.userService.getUserId(),
      lesson: id,
      code
    });
  }

  search(query: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/lesson/search`, query);
  }

  fakeRequest(data?: any) {
    return this.http.get('http://localhost:3000');
  }

}
