import { EUserLessonAction, UserLessonActions } from '../actions/userLesson.action';
import { IUserLessonState, initialUserLessonState } from '../state/userlesson.state';

export function userLessonReducers(
    state = initialUserLessonState,
    action: UserLessonActions
): IUserLessonState {
    switch (action.type) {
        case EUserLessonAction.InsertLessonToLocal:
            const newLesson = action.payload;
            const lessons = [...state.lessons, newLesson];
            return {
                ...state,
                lessons
            };
        case EUserLessonAction.GetLessonsSuccess:
            return {
                ...state,
                lessons: action.payload
            };
        case EUserLessonAction.GetLessonInEditionSuccess:
            return {
                ...state,
                inEdition: action.payload
            };
        case EUserLessonAction.CreateChapterSuccess:
            return {
                ...state,
                newChapter: true
            };
        case EUserLessonAction.NewChapterChecked:
            return {
                ...state,
                newChapter: false
            };
        case EUserLessonAction.UpdateChapterNameSuccess:
        case EUserLessonAction.UpdateChapterContentSuccess:
            return {
                ...state,
                inEdition: action.payload
            };
        case EUserLessonAction.GetLessonSuccess:
            return {
                ...state,
                selectedLesson: action.payload
            };
        case EUserLessonAction.GetLessonMinSuccess:
            return {
                ...state,
                selectedLesson: action.payload
            };
        case EUserLessonAction.SearchLessonSuccess:
            return {
                ...state,
                search: action.payload
            };
        case EUserLessonAction.StartLessonLoading:
            return {
                ...state,
                loading: true
            };
        case EUserLessonAction.StopLessonLoading:
            return {
                ...state,
                loading: false
            };
        default:
            return {
                ...state
            };
    }
}
