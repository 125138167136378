import { Injectable } from '@angular/core';
import { RequestService } from '../request/request.service';
import { UserService } from '../user/user.service';

import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { IAppState } from '../../store/state/app.state';
import { selectUserSession } from '../../store/selectors/user.selector';
import { CheckUserToken } from '../../store/actions/user.action';
@Injectable({
  providedIn: 'root'
})
export class SessionService {
  session: any = false;

  constructor(
    private router: Router,
    private rqs: RequestService,
    private store: Store<IAppState>
    ) {
      this.store.select(selectUserSession).pipe().subscribe(session => {
        if (session) {
          this.session = true;
        } else if (session === false) {
          this.session = false;
          // this.router.navigate(['/courses']);
        }
      });
  }

  tryInit() {
    const localuserId = localStorage.getItem('virsc_user_id');
    if (localuserId) {
      this.rqs.setHeaderToken(localStorage.getItem('virsc_user_token'));
      this.store.dispatch(new CheckUserToken(localuserId));
    } else {
      // this.router.navigate(['/auth/login']);
    }
  }
  getSession() {
    return this.session;
  }
}
