import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IUser } from 'src/app/models/user.interface';
import { Store } from '@ngrx/store';
import { IAppState } from '../../store/state/app.state';
import { selectUserdata } from '../../store/selectors/user.selector';
import { RequestService } from '../request/request.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private apiUrl;

  user: IUser;

  constructor(
    private http: HttpClient,
    private store: Store<IAppState>,
    private rqs: RequestService
  ) {
    this.store.select(selectUserdata).pipe().subscribe(u => this.user = u);
    this.apiUrl = this.rqs.apiUrl;
  }

  getUserId(): string {
    return this.user._id;
  }

  getUserData(userId = this.user._id): Observable<any> {
    return this.http.get(`${this.apiUrl}/user/read/${userId}`, this.rqs.getHeaderToken());
  }

  checkLogin(login: {email: string, password: string}): Observable<any> {
    console.log('login', login);
    return this.http.post(`${this.apiUrl}/login/checklogin`, login);
  }

  saveUser(userData: any): Observable<any> {
    console.log('try to save', userData);
    return this.http.post(`${this.apiUrl}/login/create`, userData);
  }

  updateUserData(data): Observable<any> {
    console.log('in  service', data);
    return this.http.post(`${this.apiUrl}/user/update/${this.user._id}`, {content: data});
  }

}
